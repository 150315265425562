<script>
import { Bar } from "vue-chartjs";
import { apiRequest } from "@/helpers/api-call";

export default {
  props: ['codeparrainage', 'actualYear'], 
  extends: Bar,
  data() {
    return {
      dataGain: null,
      dataNombre: [],
      dataMontant: [],

  
      dataMois: [],
      today: new Date(),

      mois: [
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Jui",
        "Juil",
        "Août",
        "Sept",
        "Oct",
        "Nov",
        "Déc",
      ],



    };
  },
  watch: {
    codeparrainage: {
      handler(val) {
        console.log("val:", val);
        this.loadData();
      },
    },

    actualYear: {
      handler(val) {
        console.log("val:", val);
        this.loadData();
      },
    },
  },
  async mounted() {
   console.log("Code parrainage inscription:", this.codeparrainage);

    this.loadData();
    
  },
  methods: {
    async loadData() {
      this.dataNombre = [];
      this.dataMois = [];

      const stat = await apiRequest(
        "POST",
        "historique/transactions-in/count",
        {
            "codeparrainage":this.codeparrainage
        },
        false
      );

      if (stat && stat.data) {
        var dataStat = stat.data;
        this.dataNombre=[]
        this.dataMois=[],
        this.dataMontant=[]
        dataStat.forEach((element) => {
          if (element.annee == this.actualYear) {
            this.dataNombre.push(element.nombreDeChargement);
            this.dataMois.push(this.mois[element.mois - 1]);
            this.dataMontant.push(element.montantTotal);
          }
        });
console.log(" this.dataNombre ",  this.dataNombre);
        this.renderChart(
          {
            labels: this.dataMois,
            datasets: [
              {
                label: "Nombre recharge wallet",
                barPercentage: 0.6,
                backgroundColor: "#89b800",
                borderColor: "#89b800",
                borderWidth: 2,
                shadowOffsetX:-1,
                shadowOffsetY : 1,
                shadowColor :"#79a300",
         
                hoverBackgroundColor: "#89b800",
                hoverBorderColor: "#89b800",
                data: this.dataNombre,
              },

              {
                label: "Montant recharge wallet",
                barPercentage: 0.6,
                backgroundColor: "#ff8c00",
                borderColor: "#ff8c00",
                borderWidth: 2,
                shadowOffsetX:-1,
                shadowOffsetY : 1,
                shadowColor :"#79a300",
         
                hoverBackgroundColor: "#ff8c00",
                hoverBorderColor: "#ff8c00",
                data: this.dataMontant,
              },


            ],
          },
          {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)",
                  },
                },
              ],
            },
          }
        );
      }
    },
  },
};
</script>


