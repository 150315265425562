<script>
import { Bar } from "vue-chartjs";
import { apiRequest } from "@/helpers/api-call";

export default {
  props: ['codeparrainage', 'actualYear'], 
  extends: Bar,
  data() {
    return {
      dataGain: null,
      dataNombre: [],
  
      dataMois: [],
      today: new Date(),

      mois: [
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Jui",
        "Juil",
        "Août",
        "Sept",
        "Oct",
        "Nov",
        "Déc",
      ],



    };
  },
  watch: {
    codeparrainage: {
      handler() {
       
        this.loadData();
      },
    },


    actualYear: {
      handler() {
       
        this.loadData();
      },
    },
  },
  async mounted() {


    this.loadData();
    
  },
  methods: {
    async loadData() {
      this.dataNombre = [];
      this.dataMois = [];

      const stat = await apiRequest(
        "POST",
        "historique/reservations/count",
        {
            "codeparrainage":this.codeparrainage
        },
        false
      );

      if (stat && stat.data) {
        var dataStat = stat.data;
        this.dataNombre=[]
        this.dataMois=[]
        dataStat.forEach((element) => {
          if (element.annee == this.actualYear) {
            this.dataNombre.push(element.nombreDeReservation);
            this.dataMois.push(this.mois[element.mois - 1]);
          }
        });

        this.renderChart(
          {
            labels: this.dataMois,
            datasets: [
              {
                label: "Trajets réservations",
                barPercentage: 0.6,
                backgroundColor: "#dc143c",
                borderColor: "#dc143c",
                borderWidth: 2,
                shadowOffsetX:-1,
                shadowOffsetY : 1,
                shadowColor :"#79a300",
         
                hoverBackgroundColor: "#dc143c",
                hoverBorderColor: "#dc143c",
                data: this.dataNombre,
              },
            ],
          },
          {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)",
                  },
                },
              ],
            },
          }
        );
      }
    },
  },
};
</script>


